<template>
  <form-field-text
    v-model="inputValue"
    @input:debounce="onChange"
    :label="label"
    v-bind="$attrs"
  />
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class InputCurrency extends Vue {
  @VModel() inputValue!: number | null;
  @Prop() label!: string;
  onChange() {
    this.$emit("currency_input_change", this.inputValue);
  }
}
</script>
